<template>
  <v-row justify="start">
    <v-dialog v-model="editRecomend" scrollable persistent max-width="600px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">Edit rekomendasi</span>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="px-2">
          <v-container v-if="dataRecomendation">
            <v-textarea
              name="input-7-1"
              label="Tulis rekomendasi"
              solo
              v-model="dataRecomendation.catatan"
              hide-details
            ></v-textarea>
          </v-container>
        </v-card-text>
        <Waiting v-if="loading" />
        <notifications group="validate" class="mt-2" position="top center" />
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" outlined rounded @click="giveRecomendation()"
            >Post</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import Waiting from "../../_base/Modal/waiting";

export default {
  name: "EDITRECOMENDATION",

  props: ["editRecomend", "dataRecomendation"],
  components: { Waiting },

  data() {
    return {
      loading: false,
      recomendation: ""
    };
  },

  methods: {
    giveRecomendation() {
      let body = {
        penerima_rekomendasi: this.dataRecomendation.penerima_rekomendasi,
        catatan: this.dataRecomendation.catatan
      };
      let data = {
        id: this.dataRecomendation.id,
        body: body
      };
      this.$store
        .dispatch("psycholog/updateRecomendation", data)
        .then(data => {
          this.$emit("refetch", this.dataRecomendation.penerima_rekomendasi);
          this.$emit("close");
          this.loading = false;
          Swal.fire({
            icon: "success",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        })
        .catch(err => {
          for (let i in err) {
            this.alert("error", `${err[i][0]}`);
          }
          this.loading = false;
        });
    },

    alert(type, title) {
      this.$notify({
        group: "validate",
        type: type,
        title: title,
        duration: 5000
      });
    }
  }
};
</script>

<style scoped>
.add-width {
  width: 170px;
}
.temp {
  position: relative;
}
.over {
  position: absolute;
  margin-top: -5px;
  margin-right: -5px;
}
.img-temp__ {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px black dotted;
}
.bg {
  background: #ffff;
}
</style>
