<template>
  <div class="_bg-default _80w">
    <PsychologIsAuth v-if="role" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import PsychologIsAuth from "../Psycholog/psychologIsAuth.vue";
export default {
  name: "viewPsycholog",
  computed: {
    ...mapState({
      role: (state) => state.role,
    }),
  },
  components: { PsychologIsAuth },
  watch: {
    role(val) {
      if (!val) {
        let username = this.$route.params.username;
        this.$router.push(`/${username}`);
      }
    },
  },
};
</script>

<style scoped>
.cover {
  background: url("../../assets/img/meptest.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
}
.u__u {
  width: 70%;
}
</style>
